import React, { useState } from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import GConf from '../Assets/generalConf';
import axios from 'axios';
import { useEffect } from 'react';
import { Input, Select , Checkbox,  Button, Icon, Divider, Form, TextArea, Loader, Modal, Dropdown} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents  } from 'react-leaflet';
import { useNavigate} from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import detectRTL from 'rtl-detect';
import ReactGA from 'react-ga';
 
ReactGA.initialize('G-JHPD6D9RGH');
const MapEventsHandler = ({ onLocationSelected }) => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        onLocationSelected({ lat, lng });
      },
    });
  
    return null;
}

const GeneralProfileData = ({inscData, setInscData, PDL, tag, GConf, GouvChanged, gouvList ,setGouvListe, targetSystem, OnKeyPressFunc}) =>{
    const { t, i18n } = useTranslation();
    const isRTL = detectRTL.isRtlLang(i18n.language);
    const GenresList = {
        docteur: [
            { id: 1, name: 'أسنان', value: 'STOMATOLOGIE', imgSrc: 'STOMATOLOGIE' },
                { id: 2, name: 'أطفال', value: 'PEDIATRIE', imgSrc: 'PEDIATRIE' },
                { id: 3, name: 'المجاري البولية', value: 'UROLOGIE', imgSrc: 'UROLOGIE' },
                { id: 4, name: 'امراض الرئة', value: 'PNEUMOLOGIE', imgSrc: 'PNEUMOLOGIE' },
                { id: 5, name: 'العيون', value: 'OPHTALMOLOGIE', imgSrc: 'OPHTALMOLOGIE' },
                { id: 6, name: 'أذن أنف حنجرة', value: 'ORL', imgSrc: 'ORL' },
                { id: 7, name: 'الأعصاب', value: 'NEUROLOGIE', imgSrc: 'NEUROLOGIE' },
                { id: 8, name: 'الكلي', value: 'NEPHROLOGIE', imgSrc: 'NEPHROLOGIE' },
                { id: 9, name: 'نساء و توليد', value: 'GYNECOLOGIE_OBSTETRIQUE', imgSrc: 'GYNECOLOGIE_OBSTETRIQUE' },
                { id: 10, name: 'الجهاز الهضمي', value: 'GASTROLOGIE', imgSrc: 'GASTROLOGIE' },
                { id: 11, name: 'الغدد', value: 'ENDOCRINOLOGIE', imgSrc: 'ENDOCRINOLOGIE' },
                { id: 12, name: 'البشرة', value: 'DERMATOLOGIE', imgSrc: 'DERMATOLOGIE' },
                { id: 13, name: 'القلب', value: 'CARDIOLOGIE', imgSrc: 'CARDIOLOGIE' },
                { id: 14, name: 'النفس', value: 'PSYCHIATRIE', imgSrc: 'PSYCHIATRIE' },
                { id: 15, name: 'التغذية', value: 'NUTRITION', imgSrc: 'NUTRITION' },
        ],
        garderie : [
            { id: 1, name: 'تحضيري', value: 'dentiste', imgSrc: 'nursery' },
            { id: 2, name: 'محضنة', value: 'dentiste', imgSrc: 'nurserya' },
        ],
        gym : [],
        restaurant : [
            { id: 1, name: '5 نُجُومْ', value: 'dentiste', imgSrc: 'kaftaji' },
            { id: 2, name: 'أُكْلَة خَفِيفَة', value: 'dentiste', imgSrc: 'resto1' },
            { id: 3, name: 'مَلاَوِي', value: 'dentiste', imgSrc: 'mlawi' },
            { id: 4, name: 'بِتْزَا', value: 'dentiste', imgSrc: 'pizza' },
        ],
        magazin : [
            { id: 1, name: 'magazin', value: 'dentiste', imgSrc: 'magazin' },
            { id: 2, name: 'superette', value: 'dentiste', imgSrc: 'suppertte' },
            { id: 3, name: 'عطار ', value: 'dentiste', imgSrc: 'small_shop' },
        ],
        autoecole : [
            { id: 1, name: 'صِنْفْ بَ', value: 'dentiste', imgSrc: 'tourism' },
            { id: 2, name: 'حَافِلَة', value: 'dentiste', imgSrc: 'car' },
            { id: 3, name: 'تَاكْسِي وَ  لُوَّاجْ', value: 'dentiste', imgSrc: 'taxi' },
            { id: 4, name: 'وَزْنْ ثَقِيلْ', value: 'dentiste', imgSrc: 'poid' },
        ],
    }

    const GenerateGenreListe = () =>  {
       return GenresList[tag].map(item => ({
            key: item.id.toString(),
            value: item.name,
            text: item.name,
            image: { src: `https://cdn.abyedh.com/images/Search/Land_icons/${item.imgSrc}.gif`, avatar: true }
        }));
    }

    return(<>
            <h5 className={`text-end text-secondary `} dir='rtl'> <span className='bi bi-house-heart-fill'></span>   معلومات عامة عن {targetSystem.businesName}  </h5>
            <div className='row'>
                <div className='col-12 col-lg-6 '> 
                    <div className='mb-2'>
                        <small> إسم {targetSystem.businesName}  </small>
                        <Input onKeyPress={event => OnKeyPressFunc(event)} fluid icon='users' className='w-100 text-end font-droid' placeholder={` إسم ${targetSystem.businesName}`} value={inscData.name} onChange={(e) => setInscData({...inscData, name: e.target.value })}   />
                    </div>
                    <small>  نوع  {targetSystem.businesName} </small>
                    <Dropdown
                        search
                        selection
                        fluid
                        wrapSelection={false}
                        options={GenerateGenreListe()}
                        placeholder={ `نوع  ${targetSystem.businesName}`}
                        className='mb-1'
                        onChange={(e, { value }) => setInscData({...inscData, Genre: value })}
                        value={setInscData.Genre}
                    />
                    <div className='mb-2'>
                        <small> رقم الهاتف  </small>
                        <Input onKeyPress={event => OnKeyPressFunc(event)} fluid icon='phone' className='w-100 '  placeholder=' رقم الهاتف '   value={inscData.phone} onChange={(e) => setInscData({...inscData, phone: e.target.value })}  />
                    </div>
                    <div className='mb-2'>
                        <small>  الموقع الجغرافي  </small>
                        <Select placeholder='الولاية' fluid className='mb-2' options={gouvList} value={inscData.gouv} onChange={(e, { value }) => GouvChanged('profile', value)} />
                        <Dropdown
                            fluid
                            search
                            selection
                            placeholder = {` المدينة`}
                            options={PDL}
                            value={inscData.deleg} 
                            onChange={(e, data) => setInscData({...inscData, deleg: data.value })}
                        />
                        {/* <Select placeholder={t('subscribeToSystems.infoGeneraleData.GouvDelegTextPlaceholder')} fluid options={PDL} value={inscData.deleg} onChange={(e, data) => setInscData({...inscData, deleg: data.value })} /> */}
                    </div>
                    <div className='mb-2'>
                        <small>   عنوان {targetSystem.businesName} </small>
                        <Form>
                            <TextArea onKeyPress={event => OnKeyPressFunc(event)} className='font-droid' placeholder={`عنوان ${targetSystem.businesName}`}  value={inscData.adresse} onChange={(e) => setInscData({...inscData, adresse: e.target.value })} />
                        </Form>
                    </div>
                </div>
                <div className='col-12 col-lg-6 d-none d-lg-flex align-self-center'><img src={`https://cdn.abyedh.com/Images/ads/${tag}.svg`} className='img-responsive ' width='100%' height='200px'/></div>
            </div>
    </>)
}
const Horaire = ({alwaysState, setAlwaysState, timming, setTimming, setPauseDay , SetTimmingData,UpdateTimmingData, setSelectedUpdateDay, selectedUpdateDay}) =>{
    const { t, i18n } = useTranslation();
    const isRTL = detectRTL.isRtlLang(i18n.language);

    let [addInput, setAddInput] = useState(false)
    let [dateDataToChange, setDateDataToChange] = useState({pauseDay: false, matinStart:'08:00', matinFinsh:'12:00', soirStart:'14:00', soirFinsh:'18:00'})
    const weekDays = [
        { key: 'af', value: 'Lun', text: 'lundi' },
        { key: 'ax', value: 'Mar', text: 'mardi' },
        { key: 'al', value: 'Mer', text: 'mercredi'},
        { key: 'dz', value: 'Jeu', text: 'jeudi' },
        { key: 'as', value: 'Vend', text: 'vendredi' },
        { key: 'ad', value: 'Sam', text: 'samedi' },
        { key: 'ao', value: 'Dim', text: 'dimanche' },
    ]
    const ArabificationDate = (dateName) =>{
        switch (dateName) {
            case 'Lun' : return 'الإثــنين' 
            break;
            case 'Mar' : return 'الثلاثــاء'
            break;
            case 'Mer' : return 'الإربــعاء'
            break;
            case 'Jeu' : return 'الخميس'
            break;
            case 'Vend' : return 'الجـمعة'
            break;
            case 'Sam' : return 'الســبت'
            break;
            case 'Dim' : return 'الأحـــد'
            break;

            default:
                break;
        }
    }
    const DayHoraire = (props) =>{
        return(<>
                <div className={`row  mb-1 ${props.data.dayOff ? 'text-danger':''}`}>
                    <div  className='col-3 col-lg-3 m-0 p-1'>
                        <b>{weekDays.find(day => day.value === props.data.day)?.text }</b>
                    </div>
                    <div  className='col-4 col-lg-4  m-0 p-1'>
                        <small>{props.data.matin.start} - {props.data.matin.end}</small>
                    </div>
                    <div  className='col-4 col-lg-4  m-0 p-1'>
                        <small>{props.data.soir.start} - {props.data.soir.end}</small>
                    </div>
                    <div className='col-1 m-0 p-1'>
                        <span className='bi bi-pencil-square bi-xsm text-secondary' onClick={() => OpenEditTime(props.data.day)}></span>
                    </div>
                </div>
        </>)
    }
    const OpenEditTime = (value) =>{
        setSelectedUpdateDay(value)
        setAddInput(true)
    }
    const UpdateTimingFunc = () =>{
        const targetIndex = timming.findIndex(element => element.day === selectedUpdateDay)
        let copyOfHoraire = timming
        copyOfHoraire[targetIndex] = {day: selectedUpdateDay , dayOff: dateDataToChange.pauseDay , matin:{start: dateDataToChange.matinStart ,end: dateDataToChange.matinFinsh},soir:{start: dateDataToChange.soirStart,end: dateDataToChange.soirFinsh}}
        setTimming(copyOfHoraire)
        //SetTimmingData()
        setAddInput(!addInput)
    }
    return(<>
        <br />
        <div className=' '>
            <h5 className={`text-end text-secondary`} dir='rtl'> <span className='bi bi-calendar-week-fill'></span>  أوقات العمل  </h5>
            <div className='row'>
                <div className='col-12 col-lg-7'>
                    <div className=' '>
                        <div className='row'>
                            <div className='col-10 col-lg-9 align-self-center'> 
                                <h5 className='mb-0 text-success'> مفتوح دائما </h5>  
                                <small> عند تفعيل هذه الخاصية ستضهر في حالة مفتوح دائما  </small>
                            </div>
                            <div className='col-2 col-lg-3  align-self-center '> 
                                <div className="form-check form-switch">
                                    <input className="form-check-input form-check-input-lg" type="checkbox"  onChange={() => setAlwaysState(!alwaysState)}  checked={alwaysState} />
                                </div>
                            </div>
                        </div>
                        <Divider />
                        {addInput ? 
                                <div className=' card card-body border-div  '>
                                    <div className='text-start'><span className='bi bi-x-circle-fill  text-danger text-secondary mb-2' onClick={() => setAddInput(!addInput)}></span></div>
                                    <h5 className='mt-0'> هل يوم {ArabificationDate(selectedUpdateDay)} يوم راحة ؟  </h5>
                                    <Select options={[ { key: 'af', value: false, text: 'لا' }, { key: 'ax', value: true, text: 'نعم' }]} onChange={(e, {value}) => setDateDataToChange({... dateDataToChange, pauseDay : value})} className='mb-3'/>
                                    <div className='row mb-3 '>
                                        <div className='col-6'><Input  type='time' size='mini'  value={dateDataToChange.matinStart}  fluid className='mb-1 w-100'  onChange={(e) => setDateDataToChange({... dateDataToChange, matinStart : e.target.value})} /></div>
                                        <div className='col-6'><Input  type='time' size="mini"  value={dateDataToChange.matinFinsh} fluid className='mb-1 w-100'  onChange={(e) => setDateDataToChange({... dateDataToChange, matinFinsh : e.target.value})}/></div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-6'><Input  type='time' size='mini'  value={dateDataToChange.soirStart}   fluid className='mb-1 w-100'  onChange={(e) => setDateDataToChange({... dateDataToChange, soirStart : e.target.value})} /></div>
                                        <div className='col-6'><Input  type='time' size="mini"  value={dateDataToChange.soirFinsh}  fluid className='mb-1 w-100'  onChange={(e) => setDateDataToChange({... dateDataToChange, soirFinsh : e.target.value})}/></div>
                                    </div>
                                    <Button size='mini'     className='rounded-pill    font-droid' onClick={() => UpdateTimingFunc()} fluid  >   <Icon name='time' /> تعديل وقت يوم  {ArabificationDate(selectedUpdateDay)}  </Button>
                                </div>

                        :
                                <>
                                    <div className='row text-secondary mb-2'>
                                        <div  className='col-4 col-lg-4'> <b>اليوم</b> </div>
                                        <div  className='col-4 col-lg-4'> <small>صباح</small> </div>
                                        <div  className='col-4 col-lg-4'> <small>مساء</small> </div>
                                    </div>
                                    
                                    {
                                        timming.map( (data,index) => <DayHoraire key={index} data={data} />)
                                    }
                                </>
                        }
                        
                    </div>
                </div>
                <div className='col-12 col-lg-5 d-none'>
                    <div className='card card-body border-div'>
                        <h5>قم باختيار يوم لتعديل الوقت </h5>
                        <Select options={weekDays} onChange={(e, { value }) => setSelectedUpdateDay(value)} className='mb-3'/>
                        <div className='row mb-3 '>
                            <div className='col-6'><Input  type='time' size='mini'  value={timming.find(obj => obj.day === selectedUpdateDay).matin.start}  fluid className='mb-1 w-100'  onChange={(e) => SetTimmingData(selectedUpdateDay,'matin','start',e.target.value)} /></div>
                            <div className='col-6'><Input  type='time' size="mini"  value={timming.find(obj => obj.day === selectedUpdateDay).matin.end} fluid className='mb-1 w-100'  onChange={(e) => SetTimmingData(selectedUpdateDay,'matin','end',e.target.value)}/></div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-6'><Input  type='time' size='mini'  value={timming.find(obj => obj.day === selectedUpdateDay).soir.start}   fluid className='mb-1 w-100'  onChange={(e) => SetTimmingData(selectedUpdateDay,'soir','start',e.target.value)} /></div>
                            <div className='col-6'><Input  type='time' size="mini"  value={timming.find(obj => obj.day === selectedUpdateDay).soir.end}  fluid className='mb-1 w-100'  onChange={(e) => SetTimmingData(selectedUpdateDay,'soir','end',e.target.value)}/></div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-2 text-end'>
                                <div className="form-check form-switch">
                                    <input className="form-check-input form-check-input-lg" type="checkbox" checked={timming.find(obj => obj.day === selectedUpdateDay).dayOff}   onChange={() => setPauseDay(selectedUpdateDay,selectedUpdateDay.dayOff)}   />
                                </div>
                            </div>
                            <div className='col-10'>يوم راحة ؟ </div>
                        </div>
                        
                        <Button size='mini'     className='rounded-pill    font-droid' onClick={() => UpdateTimmingData()} fluid  >   <Icon name='time' /> تعديل  </Button>
                    </div>
                </div>
            </div>
            
        </div>
    </>)
}
const Location = ({position,handleLocationSelected,GetMyLocation}) =>{
    const { t, i18n } = useTranslation();
    const isRTL = detectRTL.isRtlLang(i18n.language);

    return(<>
        <br />
        <div className='  mb-3'>
                <div className='row'>
                        <div className={`col-6 align-self-center text-end`}><h5 className='  text-secondary ' dir='rtl'> <span className='bi bi-geo-alt-fill'></span>  الموقع الجغرافي  </h5></div>
                        <div className={`col-6 align-self-center text-start`}><Button icon='map pin' className='rounded-circle' onClick={() => GetMyLocation()}></Button></div>
                </div> 
                
                <small className='mb-3' dir='rtl'> قم بالنقر علي الزر لتحديد مكانك الحاليا إفتراضيا  </small>
                <MapContainer center={[position.Lat,position.Lng]} zoom={5} scrollWheelZoom={false} className="map-height  border-div">
                    <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MapEventsHandler onLocationSelected={handleLocationSelected} />
                    <Marker position={[position.Lat,position.Lng]}>
                        <Popup> </Popup>
                    </Marker>
                </MapContainer> 
        </div>
    </>)
}
const UserCard = ({userData, setUserData, OnKeyPressFunc}) =>{
  return(<>
       
          <div className='card card-body border-div shadow-sm'>
                  <h5 className='text-secondary text-end' >معلومات عن صاحب النظام : </h5>
                  <div className='mb-2'>
                      <small> إسم و لقب صاحب النظام </small>
                      <Input onKeyPress={event => OnKeyPressFunc(event)} fluid icon='user' className='w-100 '  placeholder= ''  value={userData.name} onChange={(e) => setUserData({...userData, name: e.target.value })}  />
                  </div>
                  <div className='mb-2'>
                      <small> رقم هاتف صاحب النظام</small>
                      <Input onKeyPress={event => OnKeyPressFunc(event)} fluid icon='phone' className='w-100 '  placeholder= ''  value={userData.phone} onChange={(e) => setUserData({...userData, phone: e.target.value })}  />
                  </div>
          </div>
      
  </>)
}

function ProfileAction() {

    /* ############### Const #################*/

    let [tag, setSelectedTag] = useState(false)
    
     
    const Today = new Date()
    const targetSystem = GConf.landing[tag]
    let [okayForCondition , setOkayForCondition] = useState(false)
    let [test , setTest] = useState(10)

    let [userData, setUserData] = useState({name :'', phone:'', birthday:Today.toISOString().split('T')[0] , gouv:'',deleg:''})
    let [inscData, setInscData] = useState({name :'', phone:'', adresse:'' , gouv:'', deleg:'', Country: 'TN'})

    
    let [UDL ,setUDL] = useState([])
    let [PDL ,setPDL] = useState([])

    let [selectedUpdateDay , setSelectedUpdateDay] = useState('Lun')
    let [alwaysState , setAlwaysState] = useState(false)
    let [timming, setTimming] = useState([{day:"Lun",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"18:00"}},{day:"Mar",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"18:00"}},{day:"Mer",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"18:00"}},{day:"Jeu",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"18:00"}},{day:"Vend",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"18:00"}},{day:"Sam",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"18:00"}},{day:"Dim",dayOff:false,matin:{start:"08:00",end:"12:00"},soir:{start:"14:00",end:"18:00"}}])
    
    let [loaderState, setLS] = useState(false)
    let [saveBtnState, setSaveBtnState] = useState(false)
    
    let [gouvList ,setGouvListe] = useState([])
    const defPosition = GConf.TunMap 

    let [position, setPosition] = useState({Lat: defPosition[0].lat, Lng: defPosition[0].lng})
    let [openModal,setOpenMoadal] = useState(false)

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();
    const isRTL = detectRTL.isRtlLang(i18n.language);
    
    let [visitValue ,setVisitValue] = useState(0)

    /* ############### UseEffect #################*/
        useEffect(() => {
             

            window.scrollTo(0, 0);
            let ValueOfVist = Math.floor(Math.random() * (999999 - 111111 + 1)) + 111111
            setVisitValue(ValueOfVist)

            const defPosition = GConf.TunMap
             
            setPosition({Lat: defPosition[0].lan, Lng: defPosition[0].lng})

            axios.post(`https://api.abyedh.com/api/application/Search/systems/fromfcb`,{
                 
                isFromFcb: ValueOfVist,
                Genre: 'systemRegistration'
            })
            .then(function (response) {
                //if (!localStorage.getItem('userEnter')) { localStorage.setItem('userEnter', response.data.Req_ID); }
            }).catch((error) => {
            if(error.request) {
                 console.log('error-52478')
            }
            });

             
    }, [])

    /* ############### Functions #################*/
    L.Icon.Default.mergeOptions(GConf.LeafleftIcon );
    const OnKeyPressFunc = (e) => {
        const charCode = e.charCode || e.keyCode;
        if (!((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || (charCode >= 48 && charCode <= 57) || charCode === 42 || charCode === 32 || charCode === 47 || (charCode >= 0x0600 && charCode <= 0x06FF))) {
            e.preventDefault();
        }
    }

    const GouvChanged = (genre,value) =>{
        if (genre == 'user') {
            setUserData({...userData, gouv: value })
            const found = GConf.TunDeleg2.filter(element => element.key === value)
            setUDL(found)
        } else {
            setInscData({...inscData, gouv: value })
            const found = GConf.TunDeleg2.filter(element => element.key === value)
            setPDL(found)
        }
    }
    const SetTimmingData = (day,time,genre,value) => {
        const targetIndex = timming.findIndex(element => element.day === day)
        let copyOfHoraire = timming

        if (time == 'matin') {
            if (genre == 'start') {
                copyOfHoraire[targetIndex].matin.start = value
                setTimming(copyOfHoraire)
            } else {
                copyOfHoraire[targetIndex].matin.end = value
                setTimming(copyOfHoraire)
            }
        } else {
            if (genre == 'start') {
                copyOfHoraire[targetIndex].soir.start = value
                setTimming(copyOfHoraire)
            } else {
                copyOfHoraire[targetIndex].soir.end = value
                setTimming(copyOfHoraire)
            }
        }

    }
    const UpdateTimmingData = (day,time,genre,value) => {
        //setTimming(...timming)
        setTest(Math.random())
        toast.success("", GConf.TostAddedToTimming)

    }  
    const setPauseDay = (day,state) =>{
        const targetIndex = timming.findIndex(element => element.day === day)
        let copyOfHoraire = timming
        copyOfHoraire[targetIndex].dayOff = !state
        setTimming(copyOfHoraire)
        setTest(Math.random())
    }
    const Inscription = () =>{
            if (!userData.name || userData.name == '') {toast.error("أدخل إسم و لقب المستخدم !", GConf.TostErrorGonf)}
            else if (!userData.phone || userData.phone == '' ) {toast.error("أدخل هاتف المستخدم !", GConf.TostErrorGonf)}
            else if (!inscData.name || inscData.name == '' ) {toast.error("أدخل  إسم العمل ", GConf.TostErrorGonf)}
            else if (!inscData.phone || inscData.phone == '' ) {toast.error("أدخل  هاتف العمل ", GConf.TostErrorGonf)}
            else if (!inscData.adresse || inscData.adresse == '' ) {toast.error("أدخل  عنوان العمل ", GConf.TostErrorGonf)}
            else if (!inscData.gouv || inscData.gouv == '' ) {toast.error("أدخل  ولاية العمل ", GConf.TostErrorGonf)}
            else if (!inscData.deleg || inscData.deleg == '' ) {toast.error("أدخل  مدينة العمل ", GConf.TostErrorGonf)}
            else if (!timming) {toast.error("أدخل أوقات العمل  !", GConf.TostErrorGonf)}
            else if (!okayForCondition ) {toast.error("يحب أن توافق علي شروط الأستخدام ", GConf.TostErrorGonf)}
            else{
                setLS(true)
                axios.post(`https://api.abyedh.com/api/application/Search/systems/save`, {
                    system : tag,
                    userData : userData,
                    UID : 100000001,
                    inscData : inscData,
                    horaireData : timming,
                    alwaysOpen : alwaysState,
                    position : position,
                    addTodirectory :  false
                }).then(function (response) {
                    if(response.data.PID) {
                        setSaveBtnState(true)
                        setLS(false)
                        setOpenMoadal(true)
                    }
                    else{
                        toast.error('حاول مرة أخري', GConf.TostSuucessGonf)
                        setLS(false)
                    }
                }).catch((error) => {
                    if(error.request) {
                      toast.error(<><div><h5>مشكل في الإتصال</h5> لم نتمكن من الوصول لقاعدة البيانات </div></>, GConf.TostInternetGonf)   
                      setLS(false)
                    }
                  });
                
            }
    }
    const handleLocationSelected = (location) => {
        setPosition({Lat: location.lat , Lng:location.lng})
    }
    const GetMyLocation = () =>{
        navigator.geolocation.getCurrentPosition(
            function(position) {
                if (!position.coords.latitude) {toast.error(<><div><h5>ENTRE VOTRE POSITION Lat</h5></div></>, GConf.TostInternetGonf)}
                else if (!position.coords.longitude) {toast.error(<><div><h5>ENTRE VOTRE POSITION Lng</h5></div></>, GConf.TostInternetGonf)}
                else{
                    setPosition({Lat:position.coords.latitude, Lng:position.coords.longitude})
                }
            },
            function(error) {
                toast.error(<><div><h5>ENTRE VOTRE POSITION</h5></div></>, GConf.TostInternetGonf)
            }
        );
    }  
    const MakeSelectedTagFunc = (selectedValue) => {
      setSelectedTag(selectedValue)
      axios.post(`https://api.abyedh.com/api/application/Search/systems/fromfcb`,{       
        isFromFcb: visitValue,
        Genre: `${selectedValue}-selected`
        })
        .then(function (response) {
            //if (!localStorage.getItem('userEnter')) { localStorage.setItem('userEnter', response.data.Req_ID); }
        }).catch((error) => {
        if(error.request) {
            console.log('error-52478')
        }
      });
    }

    /* ############### Card #################*/
    const TopNavBar = () =>{
        return(<>
                <nav className="p-2 fixed-top navshad bg-danger"  >
                    <div className='row'>
                        <div className='col-2 text-start align-self-center'>
                            <NavLink exact='true' to={`/S/L/${tag}`} className="m-0 p-0 ms-3">
                                <img  className="border-div-s  " src="https://cdn.abyedh.com/images/logo/mlogo.gif"   alt="Logo" style={{width:'20px', height:'40px',borderRadius: '10px 20px 10px 50px'}} />
                                 
                            </NavLink>
                        </div>
                        <div className='col-10 text-center align-self-center text-white'>
                        <span className='text-white ms-2'>abyedh.com</span>
                        </div>
                    </div>
                </nav>
            </>)
    }
    const BtnCard = () =>{
        return(<>
            
            <div className='  card-body   mb-3 border-div'>
            <h5 className={` text-end text-secondary `} dir='rtl'> <span className='bi bi-save'></span> تسجيل </h5>
                <div className='row'>
                    <div className='col-12 col-lg-8'>
                        <div className='text-secondary'>
                            <ul>
                                <li> عملية استكمال التسجيل قد تستغرق 15 ساعة علي الكثير للتحقق من المعلومات المدرجة . يمكنك التأكد من العملية من صفحة المتابعة  </li>
                                <li>  يتمتع كل مشترك بنسخة مصغرة و مجانية من  النظام  لإستقبال الطلبات و التواصل مع العملاء  </li>
                                <li> <span className='bi bi-exclamation-triangle-fill text-danger'></span> أي طلب تسجيل يحتوي علي معلومات خاطئة أو مضللة سيلغي آليا  </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-12 col-lg-4 align-self-center'>
                            <div className='row mb-3'>
                            <div className={`col-8  ${isRTL ? 'order-1 text-end' : '  text-start'}`}> موافق </div>
                            <div className={`col-2  ${isRTL ? 'order-2 text-end' : '  text-start'}`}>
                                    <Checkbox
                                        onChange={(e, data) => setOkayForCondition(data.checked)}
                                        checked={okayForCondition}
                                    />
                                </div>
                               
                            </div>   
                            
                            <Button   disabled={saveBtnState} icon className='rounded-pill bg-success text-white font-droid' onClick={Inscription} fluid style={{backgroundColor:GConf.landing[tag].themeColor}}>   <Icon name='world' /> تسجيل <Loader inverted active={loaderState} inline size='tiny' className='ms-2 text-danger'/></Button>
                    </div>
                </div>
            </div>
        </>)
    }
     
    
    return ( <>
        <TopNavBar />
        <br />
        <br />
        <br />
        <br />

        <div className='container container-lg font-droid' dir='rtl'>
            {tag ? 
                <>
                 
                 <h3 className='text-center ' style={{color:targetSystem.themeColor}}>  الإشتراك في {targetSystem.systemTitle}   </h3>
                 <br /><UserCard userData={userData} setUserData={setUserData} OnKeyPressFunc={OnKeyPressFunc} /> <br />
                  
                 <div className='  card-body   border-div mb-3'>
                    <GeneralProfileData OnKeyPressFunc={OnKeyPressFunc} tag={tag} GConf={GConf} inscData={inscData} setInscData={setInscData} PDL={PDL}  targetSystem={targetSystem}  GouvChanged={GouvChanged}  gouvList={GConf.TunMap} setGouvListe={setGouvListe}  />
                    <Location position={position} handleLocationSelected={handleLocationSelected} GetMyLocation={GetMyLocation} />
                    <Horaire alwaysState={alwaysState} setAlwaysState={setAlwaysState} timming={timming} setTimming={setTimming} setPauseDay={setPauseDay} SetTimmingData={SetTimmingData} setSelectedUpdateDay={setSelectedUpdateDay} selectedUpdateDay={selectedUpdateDay} UpdateTimmingData={UpdateTimmingData} />
                 </div>
                 <BtnCard /> 
                </>
                :    
                <div className='text-center p-2 text-secondary'>
                    <div className='row'>
                        <div className='col-12 col-lg-4 align-self-center text-center'><img src='https://cdn.abyedh.com/Images/required/log-in.png' className='img-responsive mb-4'  width='50%' height='auto' /></div> 
                        <div className='col-12 col-lg-8 align-self-center text-center'>
                            <h3 className='text-danger'> ماهو النظام الذي تود الحصول عليه :  </h3> 
                            {/* <h5> { t('subscribeToSystems.shouldLogInData.detailText', {  one: t(`landingPage.systemNames.${tag}`) })} </h5>  */}
                            <div className='row   mb-2   p-2  rounded-pill border shadow-sm' onClick={() => MakeSelectedTagFunc('docteur')} >
                                 <div className='col-2 align-self-center'><img src='https://cdn.abyedh.com/Images/ads/docteur.svg' width='50px' /></div>
                                 <div className='col-10 align-self-center'>  <h5 className='text-secondary'> نظام ادارة العيادات الطبية  </h5> </div>
                            </div>

                            <div className='row   mb-2   p-2  rounded-pill border shadow-sm' onClick={() => MakeSelectedTagFunc('garderie')} >
                                 <div className='col-2 align-self-center'><img src='https://cdn.abyedh.com/Images/ads/garderie.svg' width='50px' /></div>
                                 <div className='col-10 align-self-center'> <h5 className='text-secondary'> نظام ادارة    رياض الأطفال  </h5> </div>
                            </div>

                            <div className='row   mb-2   p-2  rounded-pill border shadow-sm' onClick={() => MakeSelectedTagFunc('restaurant')} >
                                 <div className='col-2 align-self-center'><img src='https://cdn.abyedh.com/Images/ads/restaurant.svg' width='50px' /></div>
                                 <div className='col-10 align-self-center'> <h5 className='text-secondary'> نظام ادارة  المطاعم </h5> </div>
                            </div>

                            <div className='row   mb-2   p-2  rounded-pill border shadow-sm' onClick={() => MakeSelectedTagFunc('magazin')} >
                                 <div className='col-2 align-self-center'><img src='https://cdn.abyedh.com/Images/ads/magazin.svg' width='50px' /></div>
                                 <div className='col-10 align-self-center'> <h5 className='text-secondary'> نظام ادارة  المغازات </h5> </div>
                            </div>

                            <div className='row   mb-2   p-2  rounded-pill border shadow-sm' onClick={() => MakeSelectedTagFunc('autoecole')} >
                                 <div className='col-2 align-self-center'><img src='https://cdn.abyedh.com/Images/ads/autoecole.svg' width='50px' /></div>
                                 <div className='col-10 align-self-center'> <h5 className='text-secondary'> نظام ادارة مدارس تعليم السياقة  </h5> </div>
                            </div>

                            <div className='row   mb-2   p-2  rounded-pill border shadow-sm' onClick={() => MakeSelectedTagFunc('gym')} >
                                 <div className='col-2 align-self-center'><img src='https://cdn.abyedh.com/Images/ads/gym.svg' width='50px' /></div>
                                 <div className='col-10 align-self-center'> <h5 className='text-secondary'> نظام ادارة القاعات الرياضية  </h5> </div>
                            </div>

                        </div> 
                    </div>      
                </div>
            }
        </div>  
        <Modal
                onClose={() => setOpenMoadal(false)}
                onOpen={() => setOpenMoadal(true)}
                open={openModal}
                dimmer= 'blurring'
                    
                >
                <Modal.Content  >
                        <h1 className='text-center text-success'>مبروك لقد تم التسجيل بنجاح </h1>
                        <h3 className='text-center text-secondary'>سنقوم بالتواصل معك في أقل من 24 ساعة </h3>
                        <Button fluid className='rounded-pill font-droid' onClick={() => window.location.href = `https://play.google.com/store/apps/details?id=tn.abyedh.twa`}> حمل تطبيق أبيض للمتابعة</Button>
                </Modal.Content>
        </Modal>
        <br />
        <br />

    </> );
}

export default ProfileAction;